import { useReducer } from 'react'

import { useParticipants } from '@gopeerproject/web-kit'
import {
  View,
  Text,
  useStyleSheet,
  createStyleSheet,
  User,
  Focusable,
  Link,
  Header,
  Divider,
  Button,
  IconButton,
  Icon
} from '@gopeerproject/ui-kit'

import {
  Popover,
  PopoverTrigger,
  PopoverContent,
  usePopoverContext
} from '@/components'

import {
  useMemberAdmissionAction,
  useMemberAdmitAllAction,
  useMemberMediaRestrictionAction,
  useMemberRestrictAudioAllAction,
  reverseMediaRestrictionStatus,
  mediaRestrictionStatusToBoolean
} from './hooks'
import { isClient } from '@gopeerproject/chuck'

import { useClassroomDispatch } from '../../contexts'
import { MediaRestrictionStatus, MediaTypes, WaitingRoomStatus } from '@/data'
import { TClassroom, TProfile } from '@/types'
import { useClassroomQuery, useMemberControlsQuery } from '../../hooks'

export const MemberControls = () => {
  const styles = useStyleSheet(getStyles)
  const dispatch = useClassroomDispatch()
  return (
    <View style={styles.container}>
      <View style={styles.headerContainer}>
        <Header
          size='md'
          title='Members'
          action='custom'
          controls={[
            {
              icon: 'dismiss',
              onPress: () => {
                dispatch({
                  type: 'toggle_sidebar',
                  payload: 'member_controls'
                })
              }
            }
          ]}
          style={styles.header}
        />
      </View>
      <View style={styles.sections}>
        <WaitingRoom />
        <Divider type='horizontal' style={styles.sectionsDivider} />
        <AVControls />
      </View>
    </View>
  )
}

const WaitingRoom = () => {
  const styles = useStyleSheet(getStyles)

  const { data } = useClassroomQuery()
  const classroom = data!

  const { data: memberControls } = useMemberControlsQuery()
  const { participants: members } = memberControls!

  const { mutateAsync: updateMemberStatus } = useMemberAdmissionAction()
  const { mutateAsync: admitAllMembers } = useMemberAdmitAllAction()

  const waitlist = members
    .filter((member) => member.status === WaitingRoomStatus.WAITING)
    .map((member) => {
      return {
        id: member.id,
        ...classroom.participants[member.id]
      }
    })

  return (
    <View>
      <SectionHeader
        title={
          waitlist.length > 0
            ? `${waitlist.length} student(s) waiting to join`
            : 'Waitlist is empty'
        }
        action='Admit All'
        onAction={() => admitAllMembers()}
        disabled={waitlist.length === 0}
      />

      {waitlist.map((p) => (
        <Member
          key={p.id}
          participant={p}
          actions={
            <View style={styles.actionsContainer}>
              <Button
                leftIcon='checkmark'
                size='xs'
                text='Admit'
                type='secondary'
                accent='default'
                centered
                onPress={() => {
                  updateMemberStatus({
                    participantId: p.id,
                    status: WaitingRoomStatus.ADMITTED
                  })
                }}
              />
              <IconButton
                value='dismiss'
                onPress={() => {
                  updateMemberStatus({
                    participantId: p.id,
                    status: WaitingRoomStatus.REJECTED
                  })
                }}
                size='xs'
                type='secondary'
                accent='neutral'
              />
            </View>
          }
        />
      ))}
    </View>
  )
}

const AVControls = () => {
  const styles = useStyleSheet(getStyles)

  type State = Record<string, boolean>
  type Action =
    | { type: 'toggle'; popover: string }
    | { type: 'set'; value: boolean; popover: string }
  const [popovers, dispatch] = useReducer(
    (state: State, action: Action): State => {
      const { popover, type } = action
      switch (type) {
        case 'toggle':
          return { ...state, [popover]: !state[popover] }
        case 'set':
          return { ...state, [popover]: action.value }
        default:
          return state
      }
    },
    {}
  )

  const { mutateAsync: muteAll } = useMemberRestrictAudioAllAction()

  const joinedParticipantIds = useParticipants().map((p) => p.identity)

  const { data } = useClassroomQuery()
  const classroom = data!
  const { data: memberControls } = useMemberControlsQuery()
  const { participants: members } = memberControls!

  const admitted = members.filter(
    (member) => member.status === WaitingRoomStatus.ADMITTED
  )

  const joinedParticipants = joinedParticipantIds
    .filter(
      (pid) =>
        !!classroom.participants[pid] &&
        admitted.find((a) => a.id === pid) &&
        isClient(classroom.participants[pid].type)
    )
    .map((pid) => {
      return {
        id: pid,
        ...classroom.participants[pid],
        ...admitted.find((a) => a.id === pid)
      }
    })

  return (
    <View>
      <SectionHeader
        title={
          joinedParticipants.length > 0
            ? `${joinedParticipants.length} student(s) have joined`
            : 'No students have joined'
        }
        action='Mute All'
        onAction={() => {
          muteAll()
        }}
        disabled={
          joinedParticipants.filter(
            (p) => p.audio === MediaRestrictionStatus.UNRESTRICTED
          ).length === 0
        }
      />

      {joinedParticipants.map((p) => (
        <Member
          key={p.id}
          participant={p}
          actions={
            <View style={styles.actionsContainer}>
              {/* @ts-expect-error uikit */}
              <IconButton
                value={
                  mediaRestrictionStatusToBoolean(p.audio) ? 'mic' : 'mic-off'
                }
                size='xs'
                type='tertiary'
                accent={
                  mediaRestrictionStatusToBoolean(p.audio)
                    ? 'default'
                    : 'neutral'
                }
                active
                disabled={!mediaRestrictionStatusToBoolean(p.audio)}
              />
              {/* @ts-expect-error uikit */}
              <IconButton
                value={
                  mediaRestrictionStatusToBoolean(p.video)
                    ? 'video'
                    : 'video-off'
                }
                size='xs'
                type='tertiary'
                accent={
                  mediaRestrictionStatusToBoolean(p.video)
                    ? 'default'
                    : 'neutral'
                }
                active
                disabled={!mediaRestrictionStatusToBoolean(p.video)}
              />

              <Popover
                placement='bottom-end'
                open={popovers[p.id]}
                onOpenChange={(isOpen) =>
                  dispatch({
                    type: 'set',
                    popover: p.id,
                    value: isOpen
                  })
                }
              >
                <PopoverTrigger>
                  <IconButton
                    value='more-horizontal'
                    size='xs'
                    type='tertiary'
                    accent={'tertiary' as 'neutral'}
                    active
                    onPress={() =>
                      dispatch({
                        type: 'toggle',
                        popover: p.id
                      })
                    }
                  />
                </PopoverTrigger>
                <PopoverContent>
                  <ControlsPopover participant={p} />
                </PopoverContent>
              </Popover>
            </View>
          }
        />
      ))}
    </View>
  )
}

const ControlsPopover: React.FC<{
  participant: {
    id: string
    status?: WaitingRoomStatus | undefined
    video?: MediaRestrictionStatus | undefined
    audio?: MediaRestrictionStatus | undefined
    name: string
    image?: string
    type: TProfile['type']
  }
}> = ({ participant }) => {
  const styles = useStyleSheet(getStyles)
  const { mutateAsync: toggleMediaRestriction } =
    useMemberMediaRestrictionAction()
  const { mutateAsync: updateMemberStatus } = useMemberAdmissionAction()
  const { setOpen } = usePopoverContext()

  // const { profile } = useAuth()
  // const { mutateAsync: createFile } = useFileCreate()

  return (
    <View style={styles.controlsPopoverWrapper}>
      <Focusable
        style={styles.controlsPopoverItem}
        hoverStyle={styles.controlsPopoverItemHover}
        onPress={() => {
          // @TODO
          // createFile({
          //   name: `${participant.name} ${moment(new Date()).format(
          //     'ddd hh:mma, MMM Do'
          //   )}`,
          //   type: 'sketch',
          //   participants: [profile.id, participant.id]
          // }).then((file) => {
          //   ClassroomManager.openFile({
          //     id: file.id,
          //     type: file.type,
          //     name: file.name
          //   })
          //   if (!ClassroomManager.isPanelOpen('sketch'))
          //     ClassroomManager.togglePanel('sketch')
          // })
          // setOpen(false)
        }}
      >
        <Icon name='draw-shape' size={16} />
        <Text
          size='md'
          type='body'
          weight='regular'
          style={styles.controlsPopoverText}
        >
          Start sketching with student
        </Text>
      </Focusable>

      <Divider type='horizontal' style={styles.controlsPopoverDivider} />

      <Focusable
        style={styles.controlsPopoverItem}
        hoverStyle={styles.controlsPopoverItemHover}
        onPress={() => {
          toggleMediaRestriction({
            participantId: participant.id,
            mediaType: MediaTypes.AUDIO,
            status: reverseMediaRestrictionStatus(participant.audio)
          })
          setOpen(false)
        }}
      >
        <Icon
          name={
            mediaRestrictionStatusToBoolean(participant.audio)
              ? 'mic-off'
              : 'mic'
          }
          size={16}
        />
        <Text
          weight='regular'
          size='md'
          type='body'
          style={styles.controlsPopoverText}
        >
          {mediaRestrictionStatusToBoolean(participant.audio)
            ? 'Restrict audio'
            : 'Stop restricting audio'}
        </Text>
      </Focusable>

      <Focusable
        style={styles.controlsPopoverItem}
        hoverStyle={styles.controlsPopoverItemHover}
        onPress={() => {
          toggleMediaRestriction({
            participantId: participant.id,
            mediaType: MediaTypes.VIDEO,
            status: reverseMediaRestrictionStatus(participant.video)
          })
          setOpen(false)
        }}
      >
        <Icon
          name={
            mediaRestrictionStatusToBoolean(participant.video)
              ? 'video-off'
              : 'video'
          }
          size={16}
        />
        <Text size='md' type='body' weight='regular'>
          {mediaRestrictionStatusToBoolean(participant.video)
            ? 'Restrict video'
            : 'Stop restricting video'}
        </Text>
      </Focusable>

      <Divider type='horizontal' style={styles.controlsPopoverDivider} />

      <Focusable
        style={styles.controlsPopoverItem}
        hoverStyle={styles.controlsPopoverItemHover}
        onPress={() => {
          updateMemberStatus({
            participantId: participant.id,
            status: WaitingRoomStatus.REJECTED
          })
          setOpen(false)
        }}
      >
        <Icon name='hand' size={16} />
        <Text size='md' type='body' weight='regular'>
          Kick out
        </Text>
      </Focusable>
    </View>
  )
}

const Member: React.FC<{
  actions: React.ReactNode
  participant: TClassroom['participants'][0]
}> = ({ participant, actions = null }) => {
  const styles = useStyleSheet(getStyles)

  return (
    <Focusable
      style={styles.memberContainer}
      hoverStyle={styles.memberContainerHovered}
    >
      <View style={styles.member}>
        <User
          name={participant.name}
          type={participant.type}
          size='sm'
          image={participant.image}
        />
        <Text size='md' type='body' weight='semibold' style={styles.memberName}>
          {participant.name}
        </Text>
      </View>
      {actions}
    </Focusable>
  )
}

const SectionHeader: React.FC<{
  title: string
  action: string
  onAction: () => void
  disabled: boolean
}> = ({ title, action, onAction, disabled }) => {
  const styles = useStyleSheet(getStyles)

  return (
    <View style={styles.sectionHeader}>
      <Text
        type='body'
        size='md'
        style={styles.sectionHeaderTitle}
        weight='regular'
      >
        {title}
      </Text>
      <Link
        text={action}
        onPress={onAction}
        type='primary'
        disabled={disabled}
        style={styles.sectionHeaderAction}
      />
    </View>
  )
}

const getStyles = createStyleSheet(({ color, size }) => ({
  container: {
    width: 360,
    backgroundColor: color.bg.v1
  },
  headerContainer: {
    marginBottom: size.G,
    paddingHorizontal: size.E
  },
  header: {
    borderBottomColor: color.invert2.p4,
    borderBottomWidth: 1
  },
  sections: {
    paddingHorizontal: size.F
  },

  member: {
    alignItems: 'center',
    gap: size.C,
    flexDirection: 'row',
    flex: 1,
    minWidth: 0
  },
  memberName: {
    whiteSpace: 'nowrap',
    overflow: 'hidden',
    textOverflow: 'ellipsis'
  },
  memberContainer: {
    flexDirection: 'row',
    padding: size.E,
    alignItems: 'center',
    justifyContent: 'space-between',
    borderRadius: size.G
  },
  memberContainerHovered: {
    backgroundColor: color.invert2.p10
  },

  sectionsDivider: {
    marginVertical: size.G
  },

  sectionHeader: {
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'space-between',
    paddingHorizontal: size.E,
    marginBottom: size.C
  },
  sectionHeaderTitle: {
    color: color.invert2.p56
  },
  sectionHeaderAction: {
    height: size.K
  },

  actionsContainer: {
    flexDirection: 'row',
    alignItems: 'center',
    gap: size.B
  },

  controlsPopoverWrapper: {
    width: 259
  },
  controlsPopoverItem: {
    flexDirection: 'row',
    padding: size.E,
    gap: size.C,
    alignItems: 'center',
    borderRadius: size.G
  },
  controlsPopoverItemHover: {
    backgroundColor: color.invert2.p10
  },
  controlsPopoverIcon: {},
  controlsPopoverText: {},
  controlsPopoverDivider: {
    marginVertical: size.C,
    marginHorizontal: size.E
  }
}))
