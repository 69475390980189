import { useEffect } from 'react'
import { useLocation } from 'react-router-dom'

export const HomePage = () => {
  const location = useLocation()
  useEffect(() => {
    // redirectToCore()
  }, [])

  console.log(location.pathname)
  return null // TODO
}
