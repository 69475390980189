import './utils/newrelic'
import ReactDOM from 'react-dom/client'
import { QueryClientProvider } from 'react-query'
import { ReactQueryDevtools } from 'react-query/devtools'
import './styles/index.sass'

import { Sentry } from '@/utils'
import { AuthContextProvider, ChuckContextProvider } from '@/contexts'
import { ThemeContextProvider } from '@gopeerproject/ui-kit'
import { ModalContextProvider } from '@gopeerproject/web-kit'
import { queryClient } from '@/utils'
import { App } from './App'

Sentry.init()

ReactDOM.createRoot(document.getElementById('root')!).render(
  <QueryClientProvider client={queryClient}>
    <ChuckContextProvider>
      <AuthContextProvider>
        <ThemeContextProvider
          defaultColorScheme='dark'
          forcedColorScheme='dark'
        >
          <ModalContextProvider>
            <App />
          </ModalContextProvider>
        </ThemeContextProvider>
      </AuthContextProvider>
    </ChuckContextProvider>

    <ReactQueryDevtools
      initialIsOpen={false}
      position='bottom-right'
      closeButtonProps={{ style: { zIndex: 9999999 } }}
      panelProps={{
        style: {
          zIndex: 999999,
          maxHeight: '100%',
          height: '100%',
          width: '40%'
        }
      }}
    />
  </QueryClientProvider>
)
