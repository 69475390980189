import { createContext, useContext, useReducer } from 'react'

import { assertContext as assert } from '@/utils'
import { useClassroomEnv } from './ClasssroomEnvContext'

export enum ClassroomStep {
  // FETCHING = 'FETCHING',
  ONBOARDING = 'ONBOARDING',
  READY = 'READY'
}

export type SidebarPanel =
  | 'conversation'
  | 'member_controls'
  | 'standard_controls'

export type ClassroomState = {
  id: string
  error: unknown
  step: ClassroomStep

  notifications: { expiring: boolean }

  isFullscreenModalOpen: boolean
  isScreenShareModalOpen: boolean
  isSettingsModalOpen: boolean

  isSidebarOpen: boolean
  sidebarPanel: null | SidebarPanel

  isBackgroundLoading: boolean
  background: string

  panels: string[]
}

export type ClassroomStateActions =
  | { type: 'set_error'; payload: unknown }
  | { type: 'set_step'; payload: ClassroomStep }
  | { type: 'set_background'; payload: string }
  | { type: 'set_is_background_loading'; payload: boolean }
  | { type: 'set_notifications'; payload: { expiring: boolean } }
  | { type: 'set_panels'; payload: string[] }
  | { type: 'toggle_sidebar'; payload: SidebarPanel }
  | { type: 'open_fullscreen_modal' }
  | { type: 'close_fullscreen_modal' }
  | { type: 'open_screen_share_modal' }
  | { type: 'close_screen_share_modal' }
  | { type: 'open_settings_modal' }
  | { type: 'close_settings_modal' }

const ClassroomStateContext = createContext<ClassroomState | undefined>(
  undefined
)
const ClassroomDispatchContext = createContext<
  React.Dispatch<ClassroomStateActions> | undefined
>(undefined)

const reducer = (
  state: ClassroomState,
  action: ClassroomStateActions
): ClassroomState => {
  switch (action.type) {
    case 'set_error':
      return { ...state, error: action.payload }
    case 'set_step':
      return { ...state, step: action.payload }
    case 'set_background':
      return { ...state, background: action.payload }
    case 'set_is_background_loading':
      return { ...state, isBackgroundLoading: action.payload }
    case 'set_notifications':
      return {
        ...state,
        notifications: { ...state.notifications, ...action.payload }
      }
    case 'set_panels':
      return { ...state, panels: action.payload }
    case 'toggle_sidebar': {
      const isOpen = state.sidebarPanel === action.payload ? false : true
      return {
        ...state,
        isSidebarOpen: isOpen,
        sidebarPanel: isOpen ? action.payload : null
      }
    }
    case 'open_fullscreen_modal':
      return { ...state, isFullscreenModalOpen: true }
    case 'close_fullscreen_modal':
      return { ...state, isFullscreenModalOpen: false }
    case 'open_screen_share_modal':
      return { ...state, isScreenShareModalOpen: true }
    case 'close_screen_share_modal':
      return { ...state, isScreenShareModalOpen: false }
    case 'close_settings_modal':
      return { ...state, isSettingsModalOpen: false }
    case 'open_settings_modal':
      return { ...state, isSettingsModalOpen: true }
  }
}

export const ClassroomProvider: React.FC<{ children: React.ReactNode }> = ({
  children
}) => {
  const { id } = useClassroomEnv()

  const [state, dispatch] = useReducer(reducer, {
    error: null,
    step: ClassroomStep.ONBOARDING,
    isBackgroundLoading: false,
    background: 'default',
    notifications: { expiring: false },
    isFullscreenModalOpen: false,
    isScreenShareModalOpen: false,
    isSettingsModalOpen: false,
    isSidebarOpen: false,
    sidebarPanel: null,
    panels: [],
    id
  })

  return (
    <ClassroomStateContext.Provider value={state}>
      <ClassroomDispatchContext.Provider value={dispatch}>
        {children}
      </ClassroomDispatchContext.Provider>
    </ClassroomStateContext.Provider>
  )
}

export const useClassroomState = () => {
  return assert(
    useContext(ClassroomStateContext)!,
    'useClassroomState',
    'ClassroomStateProvider'
  )
}

export const useClassroomDispatch = () => {
  return assert(
    useContext(ClassroomDispatchContext)!,
    'useClassroomDispatch',
    'ClassroomDispatchProvider'
  )
}

export const useClassroom = () => {
  return [useClassroomState(), useClassroomDispatch()] as const
}
