import React, { useEffect } from 'react'
import {
  createStyleSheet,
  IconButton,
  useStyleSheet,
  View
} from '@gopeerproject/ui-kit'
import { useIsRecording } from '@gopeerproject/web-kit'

import { Timer } from './Timer'
import { isClient, useProfileContext } from '@gopeerproject/chuck'
import { useClassroomDispatch, useClassroomState } from '../../contexts'
import { useClassroomQuery } from '../../hooks'

export const SidebarToggles = () => {
  const styles = useStyleSheet(getStyles)

  const dispatch = useClassroomDispatch()
  const { isSidebarOpen, sidebarPanel } = useClassroomState()

  const { data } = useClassroomQuery()
  const classroom = data!
  const { profile } = useProfileContext()
  const { type: ptype } = profile!

  const isRecording = useIsRecording()

  const isConversationOpen = isSidebarOpen && sidebarPanel === 'conversation'
  const isMemberControlsOpen =
    isSidebarOpen && sidebarPanel === 'member_controls'
  const isStandardControlsOpen =
    isSidebarOpen && sidebarPanel === 'standard_controls'

  useEffect(() => {
    classroom?.type === 'group' && !isClient(ptype)
      ? dispatch({ type: 'toggle_sidebar', payload: 'member_controls' })
      : null
  }, [classroom?.type, ptype, dispatch])

  useEffect(() => {
    classroom?.type === 'group' && isClient(ptype)
      ? dispatch({ type: 'toggle_sidebar', payload: 'conversation' })
      : null
  }, [classroom?.type, ptype, dispatch])

  return (
    <View style={styles.container}>
      <Timer />
      <IconButton
        value='chat'
        type={(isConversationOpen ? 'secondary' : 'tertiary') as any}
        size='md'
        accent={(isConversationOpen ? 'default' : 'neutral') as any}
        onPress={() => {
          dispatch({ type: 'toggle_sidebar', payload: 'conversation' })
        }}
      />
      {classroom?.type === 'group' && !isClient(ptype) ? (
        <>
          <IconButton
            value='people'
            type={(isMemberControlsOpen ? 'secondary' : 'tertiary') as any}
            size='md'
            accent={(isMemberControlsOpen ? 'default' : 'neutral') as any}
            onPress={() => {
              dispatch({ type: 'toggle_sidebar', payload: 'member_controls' })
            }}
          />
          <IconButton
            value='shield-checkmark'
            size='md'
            type={(isStandardControlsOpen ? 'secondary' : 'tertiary') as any}
            accent={(isStandardControlsOpen ? 'default' : 'neutral') as any}
            onPress={() => {
              dispatch({ type: 'toggle_sidebar', payload: 'standard_controls' })
            }}
          />
        </>
      ) : null}
      {/* <IconButton
        value='emoji-hand'
        type='tertiary'
        size='md'
        accent='neutral'
      /> */}
      {isRecording ? (
        <IconButton
          value='target'
          type='secondary'
          size='md'
          accent='default'
          style={styles.target}
          onPress={() => {}}
        />
      ) : null}
    </View>
  )
}

const getStyles = createStyleSheet(({ size, color }) => ({
  container: {
    paddingLeft: size.G,
    gap: size.C,
    position: 'relative',
    zIndex: 999
  },
  target: {
    borderWidth: 1,
    borderColor: color.invert2.p4,
    borderStyle: 'solid'
  }
}))
