import React, { Dispatch, SetStateAction, useCallback } from 'react'
import Delta from 'quill-delta'
import {
  createStyleSheet,
  FileInputContextProvider,
  FileInputItem,
  Header,
  MessageInputContextProvider,
  TextInputContextProvider,
  useFileInputContext,
  useStyleSheet,
  View
} from '@gopeerproject/ui-kit'
import { DndProvider, useDrop } from 'react-dnd'
import { HTML5Backend, NativeTypes } from 'react-dnd-html5-backend'
import axios from 'axios'
import { MessageInput } from '@gopeerproject/web-kit'
import throttle from 'lodash/throttle'
import {
  getPersonFromParticipants,
  useFileUpload,
  useMessageCreation,
  useAPI,
  useProfileContext,
  useSocketContext,
  useTypingIndicator,
  uuid
} from '@gopeerproject/chuck'

import { Sentry } from '@/utils'
import {
  ConversationContextProvider,
  useClassroomDispatch,
  useConversationContext
} from '../../contexts'
import { Main } from './Main'
import { Uploader } from './Uploader'
import * as Messages from './messageUtils'
import { useClassroomQuery } from '../../hooks'

const ConversationChild: React.FC<{
  id: string
}> = ({ id }) => {
  const styles = useStyleSheet(getStyles)
  const { profile } = useProfileContext()
  const {
    visibility,
    data: conversation,
    isLoading: conversationIsLoading
  } = useConversationContext()
  const { add: addFile } = useFileInputContext()
  const connection = useSocketContext()
  const typingIndicator = useTypingIndicator(conversation)
  const messageCreation = useMessageCreation()
  const dispatch = useClassroomDispatch()

  const { id: pid } = profile!

  const [{ isOver }, dropTarget] = useDrop({
    accept: NativeTypes.FILE,
    drop: (_, monitor) => {
      if (!monitor || monitor.didDrop()) return
      addFile((monitor.getItem() as any).files)
    },
    collect: (monitor) => ({ isOver: !!monitor.isOver({ shallow: true }) })
  })

  const participant = conversationIsLoading
    ? null
    : getPersonFromParticipants(profile as any, conversation!.participants)

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const onTextChange = useCallback(
    throttle(
      () => {
        if (!connection || conversationIsLoading) return
        connection.emit('update:awareness', {
          thread: conversation!.id,
          type: 'conversation',
          data: {
            status: 'typing'
          }
        })
      },
      5 * 1000,
      { leading: true, trailing: false }
    ),
    [connection, conversationIsLoading, conversation?.id]
  )

  const send = useCallback(
    (contents: Delta, files: FileInputItem[]) => {
      if (!conversation) return
      const messages = []
      const data = Messages.transform(contents)

      if (data.length) {
        messages.push({
          type: 'text',
          data,
          localId: uuid()
        })
      }

      if (files.length) {
        messages.push({
          type: 'file',
          data: files.map(({ name, data, type, id }) => ({
            id,
            name,
            data,
            type,
            author: pid
          })),
          localId: uuid()
        })
      }

      messageCreation.mutate({
        channel: 'web',
        thread: conversation.id,
        visibleTo: [],
        visibility,
        author: pid,
        // @ts-expect-error typedef
        messages: messages
      })
    },
    [messageCreation, conversation, visibility, pid]
  )

  return (
    <View
      style={styles.container}
      //@ts-expect-error viewref
      ref={dropTarget}
    >
      <Header
        size='md'
        title='Conversation'
        action='custom'
        controls={[
          {
            icon: 'dismiss',
            onPress: () => {
              dispatch({
                type: 'toggle_sidebar',
                payload: 'conversation'
              })
            }
          }
        ]}
        style={styles.header}
      />
      <Main thread={id} />

      <MessageInputContextProvider
        // @ts-expect-error typedef
        onSubmit={send}
      >
        <MessageInput
          minNumberOfLines={1}
          richTextEnabled
          awareness={typingIndicator}
          clock={
            participant
              ? {
                  name: participant.name,
                  // @ts-expect-error typedef
                  timezone: participant.timezone?.value
                }
              : undefined
          }
          onTextChange={onTextChange}
        />
      </MessageInputContextProvider>

      {isOver && (
        <View style={styles.uploader}>
          <Uploader />
        </View>
      )}
    </View>
  )
}

export const Conversation: React.FC = () => {
  const { data } = useClassroomQuery()

  const id = data!.thread

  const { del } = useAPI()
  const uploadFile = useFileUpload()

  const upload = (
    file: FileInputItem,
    setFiles: Dispatch<SetStateAction<FileInputItem[]>>
  ) => {
    return uploadFile(
      file.data as File,
      { type: file.type, name: file.name },
      {
        onDBDocCreated: (data) =>
          // @ts-expect-error typedef
          setFiles((files) =>
            files.map((v) =>
              v.id === file.id
                ? {
                    ...v,
                    data: data.data
                  }
                : v
            )
          ),
        onUploadProgress: ({ loaded, total, progress }) =>
          setFiles((files) =>
            files.map((f) =>
              f.id === file.id
                ? {
                    ...f,
                    loaded,
                    total,
                    progress
                  }
                : f
            )
          ),
        // @ts-expect-error typedef
        canceler: file.canceler
      }
    )
      .then(({ id }) => {
        // @ts-expect-error typedef
        setFiles((files) =>
          files.map((v) =>
            v.id === file.id
              ? {
                  ...v,
                  id,
                  status: 'uploaded'
                }
              : v
          )
        )
      })
      .catch((err) => {
        if (!axios.isCancel(err)) Sentry.track(err)
      })
  }

  return (
    <ConversationContextProvider id={id}>
      <TextInputContextProvider>
        <FileInputContextProvider
          getCanceler={() => axios.CancelToken.source()}
          upload={upload}
          remove={(id, { onError }) => {
            del({ url: `/files/${id}` }).catch((err) => {
              onError?.()
              Sentry.track(err)
            })
          }}
        >
          <DndProvider backend={HTML5Backend}>
            <ConversationChild id={id} />
          </DndProvider>
        </FileInputContextProvider>
      </TextInputContextProvider>
    </ConversationContextProvider>
  )
}

const getStyles = createStyleSheet(({ color, size }) => ({
  container: {
    width: 360,
    backgroundColor: color.bg.v1,
    paddingHorizontal: size.E,
    paddingBottom: size.K
  },
  uploader: {
    position: 'absolute',
    top: 65 + 1,
    left: -size.F,
    height: `calc(100% - ${size.G}px - 65px - 1px)` as any,
    width: `calc(100% + ${size.F * 2}px)` as any,
    paddingHorizontal: size.F,
    paddingTop: size.C * 2,
    backgroundColor: color.bg.v1
  },
  header: {
    borderBottomColor: color.invert2.p4,
    borderBottomWidth: 1,
    marginBottom: size.G
  }
}))
